import { createRoot } from 'react-dom/client';
import App from './App';
import { register } from './serviceWorker';
import { changeUpdate } from './state';
import store from './config/store/store';

const container = document.getElementById('root');
const root = createRoot(container as never);
root.render(<App />);

register({
  onUpdate: registration => {
    store.dispatch(changeUpdate(registration) as never);
  },
});
