import { ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import { useFlexSalesForm, useFlexSalesFormState } from './FlexSalesForm';
import { LoadingButton } from '../buttons';
import { erroHttpErrorSelector, erroValidationErrorsSelector } from '../../state';

const ActionsContainer = styled.div`
  display: flex;
  margin-top: ${props => props.theme.spacing(2)};
  justify-content: space-around;
`;

interface ActionsProps {
  onCancel: () => void;
  submitLabel: ReactNode;
  cancelLabel: ReactNode;
}

const FlexSalesFormActions = ({ onCancel, cancelLabel, submitLabel }: ActionsProps) => {
  const { reset } = useFlexSalesForm();
  const { isSubmitting, isValid } = useFlexSalesFormState();
  const httpError = useSelector(erroHttpErrorSelector);
  const validationErrors = useSelector(erroValidationErrorsSelector);
  useEffect(() => {
    if (httpError || (validationErrors && validationErrors.length > 0)) {
      reset(undefined, { keepValues: true });
    }
  }, [httpError, validationErrors]);
  return (
    <ActionsContainer>
      <Button disabled={isSubmitting} variant="contained" onClick={onCancel}>
        {cancelLabel}
      </Button>
      <LoadingButton type="submit" loading={isSubmitting} disabled={!isValid} variant="contained">
        {submitLabel}
      </LoadingButton>
    </ActionsContainer>
  );
};

export default FlexSalesFormActions;
