import { Actions, State, ActionType } from './types';
import { AppStorage } from '../../services';

const initialState: State = AppStorage.getUserPreferences() || {
  theme: 'system',
  showSaveDialog: true,
  serviceWorkerUpdated: false,
  serviceWorkerRegistration: undefined,
};

const reducer = (state: State = initialState, action: Actions) => {
  switch (action.type) {
    case ActionType.changeTheme:
      return { ...state, theme: action.theme };
    case ActionType.changeShowSaveDialog:
      return { ...state, showSaveDialog: action.showSaveDialog };
    case ActionType.updateAvailable:
      return {
        ...state,
        serviceWorkerUpdated: !state.serviceWorkerUpdated,
        serviceWorkerRegistration: action.registration,
      };
    default:
      return state;
  }
};

export default reducer;
