import {
  Agreement,
  Cancellation,
  Closing,
  ClosingFranchiseType,
  ClosingViewMode,
  Company,
  Country,
  Discount,
  DiscountsJoin,
  ExecutionStatusPendingInterfaces,
  GCsByProduct,
  Gcs,
  GcsByPaymentTypes,
  HourRange,
  HttpRequestError,
  Management,
  MastersImport,
  MissingCauses,
  MissingClosingCollectionsCauses,
  MissingClosingSalesCauses,
  OperationType,
  TransactionType,
  PaymentType,
  PendinInterfacesViewMode,
  PendingInterfaces,
  PendingInterfacesParameters,
  PettyCashDifference,
  PettyCashDifferenceByPaymentType,
  PodOptionType,
  PosType,
  Region,
  RegionalManagement,
  Relief,
  ReportCancellationsGroupBy,
  ReportFilterGroupBy,
  ReportFilterTenderGroupBy,
  ReportReliefsGroupBy,
  ReportThirdPartySalesByItemsGroupBy,
  Sale,
  SaleType,
  SalesByProduct,
  SalesJournalFcs,
  SalesJournalGcs,
  SalesJournalNcs,
  SalesJournalThirdParty,
  Stores,
  Supervision,
  TenderReport,
  ThirdPartySalesByItems,
  TimeBand,
} from '../../types';
import {
  ActionType,
  ChangeCancellationsFilterEndAccountingDateAction,
  ChangeCancellationsFilterGroupByAction,
  ChangeCancellationsFilterStartAccountingDateAction,
  ChangeCausesFilterCauseAction,
  ChangeCausesFilterCountryAction,
  ChangeCausesFilterDateAction,
  ChangeCausesFilterStoreAction,
  ChangeClosingFilterCauseTypesAction,
  ChangeClosingFilterCountryAction,
  ChangeClosingFilterEndBusinessDateAction,
  ChangeClosingFilterFederativeEntitiesAction,
  ChangeClosingFilterFranchiseTypesAction,
  ChangeClosingFilterStartBusinessDateAction,
  ChangeClosingFilterStoreAction,
  ChangeClosingFilterViewModeAction,
  ChangeCollectionsFilterEndBusinessDateAction,
  ChangeCollectionsFilterGroupByAction,
  ChangeCollectionsFilterStartBusinessDateAction,
  ChangeExecutePendingInterfacesFilterBusinessDateAction,
  ChangeExecutePendingInterfacesFilterCountryAction,
  ChangeExecutePendingInterfacesFilterStatusAction,
  ChangeExecutePendingInterfacesFilterStoreAction,
  ChangeFilterAccountingEntriesFieldsAction,
  ChangeFilterCountryAction,
  ChangeFilterDifferenceTypeAction,
  ChangeFilterDiscountsJoinAction,
  ChangeFilterEndAccountingDateAction,
  ChangeFilterEndBusinessDateAction,
  ChangeFilterEndOperationDateAction,
  ChangeFilterGroupByAction,
  ChangeFilterHourRangeAction,
  ChangeFilterItemDiscountAction,
  ChangeFilterOperationTypeAction,
  ChangeFilterTransactionTypeAction,
  ChangeFilterPaymentTypesAction,
  ChangeFilterPluAction,
  ChangeFilterPosAction,
  ChangeFilterSaleDiscountAction,
  ChangeFilterSalesTypesAction,
  ChangeFilterSegmentsAction,
  ChangeFilterStartAccountingDateAction,
  ChangeFilterStartBusinessDateAction,
  ChangeFilterStartOperationDateAction,
  ChangeFilterStoreAction,
  ChangeFilterStoreMotherChildAction,
  ChangeFilterTenderGroupByAction,
  ChangeFilterTenderNameAction,
  ChangeFilterThirdPartySalesByItemsGroupByAction,
  ChangeFilterTimeBandAction,
  ChangeGcsFilterEndAccountingDateAction,
  ChangeGcsFilterEndBusinessDateAction,
  ChangeGcsFilterEndOperationDateAction,
  ChangeGcsFilterStartAccountingDateAction,
  ChangeGcsFilterStartBusinessDateAction,
  ChangeGcsFilterStartOperationDateAction,
  ChangeMastersImportFilterCountryAction,
  ChangeMastersImportFilterEndImportDateAction,
  ChangeMastersImportFilterStartImportDateAction,
  ChangeMastersImportFilterStateAction,
  ChangeMissingClosingTotalFilteredStoresAction,
  ChangeOpStrucuresFilterAgreementAction,
  ChangeOpStrucuresFilterCompanyAction,
  ChangeOpStrucuresFilterManagementAction,
  ChangeOpStrucuresFilterRegionAction,
  ChangeOpStrucuresFilterRegionalManagementAction,
  ChangeOpStrucuresFilterSupervisionAction,
  ChangePendingInterfacesFilterCountryAction,
  ChangePendingInterfacesFilterEndBusinessDateAction,
  ChangePendingInterfacesFilterQueryAction,
  ChangePendingInterfacesFilterStartBusinessDateAction,
  ChangePendingInterfacesFilterStoreAction,
  ChangePendingInterfacesFilterViewModeAction,
  ChangeSaleProductFilterGroupByAction,
  ExecutePendingInterfacesFailureAction,
  ExecutePendingInterfacesParametersFailureAction,
  ExecutePendingInterfacesParametersRequestedAction,
  ExecutePendingInterfacesParametersSuccessAction,
  ExecutePendingInterfacesRequestedAction,
  ExecutePendingInterfacesSuccessAction,
  FindCancellationsFailureAction,
  FindCancellationsRequestedAction,
  FindCancellationsSuccessAction,
  FindCauseTypesFailureAction,
  FindCauseTypesRequestedAction,
  FindCauseTypesSuccessAction,
  FindClosingCollectionsFailureAction,
  FindClosingCollectionsRequestedAction,
  FindClosingCollectionsSuccessAction,
  FindClosingSalesFailureAction,
  FindClosingSalesRequestedAction,
  FindClosingSalesSuccessAction,
  FindGCsByProductFailureAction,
  FindGCsByProductRequestedAction,
  FindGCsByProductSuccessAction,
  FindGcsByPaymentTypesFailureAction,
  FindGcsByPaymentTypesRequestedAction,
  FindGcsByPaymentTypesSuccessAction,
  FindGcsFailureAction,
  FindGcsRequestedAction,
  FindGcsSuccessAction,
  FindHourRangeFailureAction,
  FindHourRangeRequestedAction,
  FindHourRangeSuccessAction,
  FindItemDiscountsFailureAction,
  FindItemDiscountsRequestedAction,
  FindItemDiscountsSuccessAction,
  FindMastersImportFailureAction,
  FindMastersImportRequestedAction,
  FindMastersImportSuccessAction,
  FindMissingClosingCollectionsCausesFailureAction,
  FindMissingClosingCollectionsCausesRequestedAction,
  FindMissingClosingCollectionsCausesSuccessAction,
  FindMissingClosingSalesCausesFailureAction,
  FindMissingClosingSalesCausesRequestedAction,
  FindMissingClosingSalesCausesSuccessAction,
  FindMissingClosingTotalFailureAction,
  FindMissingClosingTotalRequestedAction,
  FindMissingClosingTotalSuccessAction,
  FindOperationTypesFailureAction,
  FindOperationTypesRequestedAction,
  FindOperationTypesSuccessAction,
  FindTransactionTypeFailureAction,
  FindTransactionTypeRequestedAction,
  FindTransactionTypeSuccessAction,
  FindPaymentTypeFailureAction,
  FindPaymentTypeRequestedAction,
  FindPaymentTypeSuccessAction,
  FindPendingInterfacesCollectionsFailureAction,
  FindPendingInterfacesCollectionsRequestedAction,
  FindPendingInterfacesCollectionsSuccessAction,
  FindPendingInterfacesSalesFailureAction,
  FindPendingInterfacesSalesRequestedAction,
  FindPendingInterfacesSalesSuccessAction,
  FindPettyCashDifferenceByPaymentTypeFailureAction,
  FindPettyCashDifferenceByPaymentTypeRequestedAction,
  FindPettyCashDifferenceByPaymentTypeSuccessAction,
  FindPettyCashDifferenceFailureAction,
  FindPettyCashDifferenceRequestedAction,
  FindPettyCashDifferenceSuccessAction,
  FindPosFailureAction,
  FindPosRequestedAction,
  FindPosSuccessAction,
  FindReliefsFailureAction,
  FindReliefsRequestedAction,
  FindReliefsSuccessAction,
  FindReportTenderFailureAction,
  FindReportTenderRequestedAction,
  FindReportTenderSuccessAction,
  FindSaleDiscountsFailureAction,
  FindSaleDiscountsRequestedAction,
  FindSaleDiscountsSuccessAction,
  FindSalesByProductFailureAction,
  FindSalesByProductRequestedAction,
  FindSalesByProductSuccessAction,
  FindSalesFailureAction,
  FindSalesJournalFcsFailureAction,
  FindSalesJournalFcsSuccessAction,
  FindSalesJournalGcsFailureAction,
  FindSalesJournalGcsSuccessAction,
  FindSalesJournalNcsFailureAction,
  FindSalesJournalNcsSuccessAction,
  FindSalesJournalThirdPartyFailureAction,
  FindSalesJournalThirdPartySuccessAction,
  FindSalesRequestedAction,
  FindSalesSuccessAction,
  FindSalesTypesFailureAction,
  FindSalesTypesRequestedAction,
  FindSalesTypesSuccessAction,
  FindThirdPartySalesByItemsFailureAction,
  FindThirdPartySalesByItemsRequestedAction,
  FindThirdPartySalesByItemsSuccessAction,
  FindTimeBandFailureAction,
  FindTimeBandRequestedAction,
  FindTimeBandSuccessAction,
  MastersImportExecutionFailureAction,
  MastersImportExecutionRequestedAction,
  MastersImportExecutionSuccessAction,
  PendingInterfacesCreateFailureAction,
  PendingInterfacesCreateSuccessAction,
  findSalesJournalRequestedAction,
} from './types';

export const changeFilterCountry = (countryCode: string): ChangeFilterCountryAction => ({
  type: ActionType.changeFiltersCountry,
  countryCode,
});

export const changeFilterPlu = (plu: number[]): ChangeFilterPluAction => ({
  type: ActionType.changeFiltersPlu,
  plu,
});

export const changeFilterStoreMotherChild = (
  pod: PodOptionType[]
): ChangeFilterStoreMotherChildAction => ({
  type: ActionType.changeFiltersStoreMotherChild,
  pod,
});

export const changeFilterStore = (stores: Stores[]): ChangeFilterStoreAction => ({
  type: ActionType.changeFiltersStore,
  stores,
});

export const changeFilterPos = (pos: string[]): ChangeFilterPosAction => ({
  type: ActionType.changeFiltersPos,
  pos,
});

export const changeFilterSalesTypes = (salesTypes: SaleType[]): ChangeFilterSalesTypesAction => ({
  type: ActionType.changeFiltersSalesTypes,
  salesTypes,
});

export const changeFilterPaymentTypes = (
  paymentTypes: PaymentType[]
): ChangeFilterPaymentTypesAction => ({
  type: ActionType.changeFiltersPaymentTypes,
  paymentTypes,
});

export const changeFilterOperationType = (
  operationType: OperationType[]
): ChangeFilterOperationTypeAction => ({
  type: ActionType.changeFiltersOperationType,
  operationType,
});

export const changeFilterTransactionType = (
  transactionType: TransactionType[]
): ChangeFilterTransactionTypeAction => ({
  type: ActionType.changeFiltersTransactionType,
  transactionType,
});

export const changeFilterTenderName = (tenderName: string): ChangeFilterTenderNameAction => ({
  type: ActionType.changeFiltersTenderName,
  tenderName,
});

export const changeFilterHourRange = (hourRange: HourRange[]): ChangeFilterHourRangeAction => ({
  type: ActionType.changeFiltersHourRange,
  hourRange,
});

export const changeFilterSaleDiscount = (discount: Discount[]): ChangeFilterSaleDiscountAction => ({
  type: ActionType.changeFiltersSaleDiscount,
  discount,
});

export const changeFilterItemDiscount = (discount: Discount[]): ChangeFilterItemDiscountAction => ({
  type: ActionType.changeFiltersItemDiscount,
  discount,
});

export const changeFilterDifferenceType = (
  differenceType: string
): ChangeFilterDifferenceTypeAction => ({
  type: ActionType.changeFiltersDifferenceType,
  differenceType,
});

export const changeFilterTimeBand = (timeBand: string[]): ChangeFilterTimeBandAction => ({
  type: ActionType.changeFiltersTimeBand,
  timeBand,
});

export const changeFilterSegments = (segments: PosType[]): ChangeFilterSegmentsAction => ({
  type: ActionType.changeFiltersSegments,
  segments,
});

export const changeFilterStartBusinessDate = (
  date: Date | null
): ChangeFilterStartBusinessDateAction => ({
  type: ActionType.changeFilterStartBusinessDate,
  date,
});

export const changeFilterEndBusinessDate = (
  date: Date | null
): ChangeFilterEndBusinessDateAction => ({
  type: ActionType.changeFilterEndBusinessDate,
  date,
});

export const changeFilterStartOperationDate = (
  date: Date | null
): ChangeFilterStartOperationDateAction => ({
  type: ActionType.changeFilterStartOperationDate,
  date,
});

export const changeFilterEndOperationDate = (
  date: Date | null
): ChangeFilterEndOperationDateAction => ({
  type: ActionType.changeFilterEndOperationDate,
  date,
});

export const changeFilterStartAccountingDate = (
  date: Date | null
): ChangeFilterStartAccountingDateAction => ({
  type: ActionType.changeFilterStartAccountingDate,
  date,
});

export const changeFilterEndAccountingDate = (
  date: Date | null
): ChangeFilterEndAccountingDateAction => ({
  type: ActionType.changeFilterEndAccountingDate,
  date,
});

export const changeFilterGroupBy = (groupBy: ReportFilterGroupBy[]): ChangeFilterGroupByAction => ({
  type: ActionType.changeFilterGroupBy,
  groupBy,
});

export const changeGcsFilterStartBusinessDate = (
  date: Date | null
): ChangeGcsFilterStartBusinessDateAction => ({
  type: ActionType.changeGcsFilterStartBusinessDate,
  date,
});

export const changeGcsFilterEndBusinessDate = (
  date: Date | null
): ChangeGcsFilterEndBusinessDateAction => ({
  type: ActionType.changeGcsFilterEndBusinessDate,
  date,
});

export const changeGcsFilterStartOperationDate = (
  date: Date | null
): ChangeGcsFilterStartOperationDateAction => ({
  type: ActionType.changeGcsFilterStartOperationDate,
  date,
});

export const changeGcsFilterEndOperationDate = (
  date: Date | null
): ChangeGcsFilterEndOperationDateAction => ({
  type: ActionType.changeGcsFilterEndOperationDate,
  date,
});

export const changeGcsFilterStartAccountingDate = (
  date: Date | null
): ChangeGcsFilterStartAccountingDateAction => ({
  type: ActionType.changeGcsFilterStartAccountingDate,
  date,
});

export const changeGcsFilterEndAccountingDate = (
  date: Date | null
): ChangeGcsFilterEndAccountingDateAction => ({
  type: ActionType.changeGcsFilterEndAccountingDate,
  date,
});

export const changeCollectionsFilterGroupBy = (
  groupBy: ReportReliefsGroupBy[]
): ChangeCollectionsFilterGroupByAction => ({
  type: ActionType.changeCollectionsFilterGroupBy,
  groupBy,
});

export const changeCollectionsFilterStartBusinessDate = (
  date: Date | null
): ChangeCollectionsFilterStartBusinessDateAction => ({
  type: ActionType.changeCollectionsFilterStartBusinessDate,
  date,
});

export const changeCollectionsFilterEndBusinessDate = (
  date: Date | null
): ChangeCollectionsFilterEndBusinessDateAction => ({
  type: ActionType.changeCollectionsFilterEndBusinessDate,
  date,
});

export const changeCancellationsFilterGroupBy = (
  groupBy: ReportCancellationsGroupBy[]
): ChangeCancellationsFilterGroupByAction => ({
  type: ActionType.changeCancellationsFilterGroupBy,
  groupBy,
});

export const changeCancellationsFilterStartAccountingDate = (
  date: Date | null
): ChangeCancellationsFilterStartAccountingDateAction => ({
  type: ActionType.changeCancellationsFilterStartAccountingDate,
  date,
});

export const changeCancellationsFilterEndAccountingDate = (
  date: Date | null
): ChangeCancellationsFilterEndAccountingDateAction => ({
  type: ActionType.changeCancellationsFilterEndAccountingDate,
  date,
});

export const changeFilterThirdPartySalesByItemsGroupBy = (
  groupBy: ReportThirdPartySalesByItemsGroupBy[]
): ChangeFilterThirdPartySalesByItemsGroupByAction => ({
  type: ActionType.changeFilterThirdPartySalesByItemsGroupBy,
  groupBy,
});

export const changeSaleProductFilterGroupBy = (
  groupBy: ReportFilterGroupBy[]
): ChangeSaleProductFilterGroupByAction => ({
  type: ActionType.changeSaleProductFilterGroupBy,
  groupBy,
});

export const changeFilterTenderGroupBy = (
  groupBy: ReportFilterTenderGroupBy[]
): ChangeFilterTenderGroupByAction => ({
  type: ActionType.changeFiltersTenderGroupBy,
  groupBy,
});

export const changeFilterDiscountsJoin = (
  discountsJoin: DiscountsJoin[]
): ChangeFilterDiscountsJoinAction => ({
  type: ActionType.changeFiltersDiscountsJoin,
  discountsJoin,
});

export const findPosRequested = (): FindPosRequestedAction => ({
  type: ActionType.findPosRequested,
});

export const findPosSuccess = (posOptions: string[]): FindPosSuccessAction => ({
  type: ActionType.findPosSuccess,
  posOptions,
});

export const findPosFailure = (error: HttpRequestError): FindPosFailureAction => ({
  type: ActionType.findPosFailure,
  error,
});

export const findSalesTypesRequested = (): FindSalesTypesRequestedAction => ({
  type: ActionType.findSalesTypesRequested,
});

export const findSalesTypesSuccess = (
  salesTypesOptions: SaleType[]
): FindSalesTypesSuccessAction => ({
  type: ActionType.findSalesTypesSuccess,
  salesTypesOptions,
});

export const findSalesTypesFailure = (error: HttpRequestError): FindSalesTypesFailureAction => ({
  type: ActionType.findSalesTypesFailure,
  error,
});

export const findCauseTypesRequested = (): FindCauseTypesRequestedAction => ({
  type: ActionType.findCauseTypesRequested,
});

export const findCauseTypesSuccess = (
  causeTypesOptions: MissingCauses[]
): FindCauseTypesSuccessAction => ({
  type: ActionType.findCauseTypesSuccess,
  causeTypesOptions,
});

export const findCauseTypesFailure = (error: HttpRequestError): FindCauseTypesFailureAction => ({
  type: ActionType.findCauseTypesFailure,
  error,
});

export const findSaleDiscountsRequested = (): FindSaleDiscountsRequestedAction => ({
  type: ActionType.findSaleDiscountsRequested,
});

export const findSaleDiscountsSuccess = (
  discountOptions: Discount[]
): FindSaleDiscountsSuccessAction => ({
  type: ActionType.findSaleDiscountsSuccess,
  discountOptions,
});

export const findSaleDiscountsFailure = (
  error: HttpRequestError
): FindSaleDiscountsFailureAction => ({
  type: ActionType.findSaleDiscountsFailure,
  error,
});

export const findItemDiscountsRequested = (): FindItemDiscountsRequestedAction => ({
  type: ActionType.findItemDiscountsRequested,
});

export const findItemDiscountsSuccess = (
  discountOptions: Discount[]
): FindItemDiscountsSuccessAction => ({
  type: ActionType.findItemDiscountsSuccess,
  discountOptions,
});

export const findItemDiscountsFailure = (
  error: HttpRequestError
): FindItemDiscountsFailureAction => ({
  type: ActionType.findItemDiscountsFailure,
  error,
});

export const findOperationTypesRequested = (): FindOperationTypesRequestedAction => ({
  type: ActionType.findOperationTypesRequested,
});

export const findOperationTypesSuccess = (
  operationTypes: OperationType[]
): FindOperationTypesSuccessAction => ({
  type: ActionType.findOperationTypesSuccess,
  operationTypes,
});

export const findOperationTypesFailure = (
  error: HttpRequestError
): FindOperationTypesFailureAction => ({
  type: ActionType.findOperationTypesFailure,
  error,
});

export const findTransactionTypeRequested = (): FindTransactionTypeRequestedAction => ({
  type: ActionType.findTransactionTypeRequested,
});

export const findTransactionTypeSuccess = (
  transactionType: TransactionType[]
): FindTransactionTypeSuccessAction => ({
  type: ActionType.findTransactionTypeSuccess,
  transactionType,
});

export const findTransactionTypeFailure = (
  error: HttpRequestError
): FindTransactionTypeFailureAction => ({
  type: ActionType.findTransactionTypeFailure,
  error,
});

export const findPaymentTypeRequested = (): FindPaymentTypeRequestedAction => ({
  type: ActionType.findPaymentTypeRequested,
});

export const findPaymentTypeSuccess = (
  paymentType: PaymentType[]
): FindPaymentTypeSuccessAction => ({
  type: ActionType.findPaymentTypeSuccess,
  paymentType,
});

export const findPaymentTypeFailure = (error: HttpRequestError): FindPaymentTypeFailureAction => ({
  type: ActionType.findPaymentTypeFailure,
  error,
});

export const findSalesRequested = (sentToOnedrive?: boolean): FindSalesRequestedAction => ({
  type: ActionType.findSalesRequested,
  sentToOnedrive: Boolean(sentToOnedrive),
});

export const findSalesSuccess = (sales: Sale[]): FindSalesSuccessAction => ({
  type: ActionType.findSalesSuccess,
  sales,
});

export const findSalesFailure = (error: HttpRequestError): FindSalesFailureAction => ({
  type: ActionType.findSalesFailure,
  error,
});

export const findReportTenderRequested = (
  sendToOnedrive?: boolean
): FindReportTenderRequestedAction => ({
  type: ActionType.findReportTenderRequested,
  sendToOnedrive: Boolean(sendToOnedrive),
});

export const findReportTenderSuccess = (
  reportTender: TenderReport[]
): FindReportTenderSuccessAction => ({
  type: ActionType.findReportTenderSuccess,
  reportTender,
});

export const findReportTenderFailure = (
  error: HttpRequestError
): FindReportTenderFailureAction => ({
  type: ActionType.findReportTenderFailure,
  error,
});

export const findGcsRequested = (sendToOndrive?: boolean): FindGcsRequestedAction => ({
  type: ActionType.findGcsRequested,
  sendToOndrive: Boolean(sendToOndrive),
});

export const findGcsSuccess = (gcs: Gcs[]): FindGcsSuccessAction => ({
  type: ActionType.findGcsSuccess,
  gcs,
});

export const findGcsFailure = (error: HttpRequestError): FindGcsFailureAction => ({
  type: ActionType.findGcsFailure,
  error,
});

export const findGcsByPaymentTypesRequested = (
  sendToOndrive?: boolean
): FindGcsByPaymentTypesRequestedAction => ({
  type: ActionType.findGcsByPaymentTypesRequested,
  sendToOndrive: Boolean(sendToOndrive),
});

export const findGcsByPaymentTypesSuccess = (
  gcsPaymentTypes: GcsByPaymentTypes[]
): FindGcsByPaymentTypesSuccessAction => ({
  type: ActionType.findGcsByPaymentTypesSuccess,
  gcsPaymentTypes,
});

export const findGcsByPaymentTypesFailure = (
  error: HttpRequestError
): FindGcsByPaymentTypesFailureAction => ({
  type: ActionType.findGcsByPaymentTypesFailure,
  error,
});

export const findClosingSalesRequested = (
  startDate?: Date,
  endDate?: Date
): FindClosingSalesRequestedAction => ({
  type: ActionType.findClosingSalesRequested,
  startDate,
  endDate,
});

export const findClosingSalesSuccess = (
  closing: Closing[],
  startDate: Date,
  endDate: Date
): FindClosingSalesSuccessAction => ({
  type: ActionType.findClosingSalesSuccess,
  closing,
  startDate,
  endDate,
});

export const findClosingSalesFailure = (
  error: HttpRequestError
): FindClosingSalesFailureAction => ({
  type: ActionType.findClosingSalesFailure,
  error,
});

export const findClosingCollectionsRequested = (
  startDate?: Date,
  endDate?: Date
): FindClosingCollectionsRequestedAction => ({
  type: ActionType.findClosingCollectionsRequested,
  startDate,
  endDate,
});

export const findClosingCollectionsSuccess = (
  closing: Closing[],
  startDate: Date,
  endDate: Date
): FindClosingCollectionsSuccessAction => ({
  type: ActionType.findClosingCollectionsSuccess,
  closing,
  startDate,
  endDate,
});

export const findClosingCollectionsFailure = (
  error: HttpRequestError
): FindClosingCollectionsFailureAction => ({
  type: ActionType.findClosingCollectionsFailure,
  error,
});

export const findMissingClosingSalesCausesRequested =
  (): FindMissingClosingSalesCausesRequestedAction => ({
    type: ActionType.findMissingClosingSalesCausesRequested,
  });

export const findMissingClosingSalesCausesSuccess = (
  missingClosingCauses: MissingClosingSalesCauses | undefined
): FindMissingClosingSalesCausesSuccessAction => ({
  type: ActionType.findMissingClosingSalesCausesSuccess,
  missingClosingCauses,
});

export const findMissingClosingSalesCausesFailure = (
  error: HttpRequestError
): FindMissingClosingSalesCausesFailureAction => ({
  type: ActionType.findMissingClosingSalesCausesFailure,
  error,
});

export const findMissingClosingCollectionsCausesRequested =
  (): FindMissingClosingCollectionsCausesRequestedAction => ({
    type: ActionType.findMissingClosingCollectionsCausesRequested,
  });

export const findMissingClosingCollectionsCausesSuccess = (
  missingClosingCauses: MissingClosingCollectionsCauses | undefined
): FindMissingClosingCollectionsCausesSuccessAction => ({
  type: ActionType.findMissingClosingCollectionsCausesSuccess,
  missingClosingCauses,
});

export const findMissingClosingCollectionsCausesFailure = (
  error: HttpRequestError
): FindMissingClosingCollectionsCausesFailureAction => ({
  type: ActionType.findMissingClosingCollectionsCausesFailure,
  error,
});

export const changeCausesFilterCountry = (country: string): ChangeCausesFilterCountryAction => ({
  type: ActionType.changeCausesFilterCountry,
  country,
});

export const changeCausesFilterStore = (store: string): ChangeCausesFilterStoreAction => ({
  type: ActionType.changeCausesFilterStore,
  store,
});

export const changeCausesFilterDate = (date: Date | null): ChangeCausesFilterDateAction => ({
  type: ActionType.changeCausesFilterDate,
  date,
});

export const changeCausesFilterCause = (cause: string): ChangeCausesFilterCauseAction => ({
  type: ActionType.changeCausesFilterCause,
  cause,
});

export const changeOpStrucuresFilterAgreement = (
  agreement: Agreement[]
): ChangeOpStrucuresFilterAgreementAction => ({
  type: ActionType.changeOpStrucuresFilterAgreement,
  agreement,
});

export const changeOpStrucuresFilterRegionalManagement = (
  regionalMgmt: RegionalManagement[]
): ChangeOpStrucuresFilterRegionalManagementAction => ({
  type: ActionType.changeOpStrucuresFilterRegionalManagement,
  regionalMgmt,
});

export const changeOpStrucuresFilterRegion = (
  region: Region[]
): ChangeOpStrucuresFilterRegionAction => ({
  type: ActionType.changeOpStrucuresFilterRegion,
  region,
});

export const changeOpStrucuresFilterManagement = (
  management: Management[]
): ChangeOpStrucuresFilterManagementAction => ({
  type: ActionType.changeOpStrucuresFilterManagement,
  management,
});

export const changeOpStrucuresFilterSupervision = (
  supervision: Supervision[]
): ChangeOpStrucuresFilterSupervisionAction => ({
  type: ActionType.changeOpStrucuresFilterSupervision,
  supervision,
});

export const changeOpStrucuresFilterCompany = (
  company: Company[]
): ChangeOpStrucuresFilterCompanyAction => ({
  type: ActionType.changeOpStrucuresFilterCompany,
  company,
});

export const findMissingClosingTotalRequested = (
  dateFrom: Date,
  dateTo: Date,
  country: string,
  stores: Stores[]
): FindMissingClosingTotalRequestedAction => ({
  type: ActionType.findMissingClosingTotalRequested,
  country,
  dateFrom,
  dateTo,
  stores,
});

export const findMissingClosingTotalSuccess = (
  totals: number
): FindMissingClosingTotalSuccessAction => ({
  type: ActionType.findMissingClosingTotalSuccess,
  totals,
});

export const findMissingClosingTotalFailure = (
  error: HttpRequestError
): FindMissingClosingTotalFailureAction => ({
  type: ActionType.findMissingClosingTotalFailure,
  error,
});

export const changeMissingClosingTotalFilteredStores = (
  stores: Stores[]
): ChangeMissingClosingTotalFilteredStoresAction => ({
  type: ActionType.changeMissingClosingTotalFilteredStores,
  stores,
});

export const findHourRangeRequested = (): FindHourRangeRequestedAction => ({
  type: ActionType.findHourRangeRequested,
});

export const findHourRangeSuccess = (hourRange: HourRange[]): FindHourRangeSuccessAction => ({
  type: ActionType.findHourRangeSuccess,
  hourRange,
});

export const findHourRangeFailure = (error: HttpRequestError): FindHourRangeFailureAction => ({
  type: ActionType.findHourRangeFailure,
  error,
});

export const findTimeBandRequested = (): FindTimeBandRequestedAction => ({
  type: ActionType.findTimeBandRequested,
});

export const findTimeBandSuccess = (timeBand: TimeBand[]): FindTimeBandSuccessAction => ({
  type: ActionType.findTimeBandSuccess,
  timeBand,
});

export const findTimeBandFailure = (error: HttpRequestError): FindTimeBandFailureAction => ({
  type: ActionType.findTimeBandFailure,
  error,
});

export const findReliefsRequested = (): FindReliefsRequestedAction => ({
  type: ActionType.findReliefsRequested,
});

export const findReliefsSuccess = (reliefs: Relief[]): FindReliefsSuccessAction => ({
  type: ActionType.findReliefsSuccess,
  reliefs,
});

export const findReliefsFailure = (error: HttpRequestError): FindReliefsFailureAction => ({
  type: ActionType.findReliefsFailure,
  error,
});

export const findCancellationsRequested = (): FindCancellationsRequestedAction => ({
  type: ActionType.findCancellationsRequested,
});

export const findCancellationsSuccess = (
  cancellations: Cancellation[]
): FindCancellationsSuccessAction => ({
  type: ActionType.findCancellationsSuccess,
  cancellations,
});

export const findCancellationsFailure = (
  error: HttpRequestError
): FindCancellationsFailureAction => ({
  type: ActionType.findCancellationsFailure,
  error,
});

export const findSalesByProductRequested = (
  sendToOndrive?: boolean
): FindSalesByProductRequestedAction => ({
  type: ActionType.findSalesByProductRequested,
  sendToOndrive: Boolean(sendToOndrive),
});

export const findSalesByProductSuccess = (
  salesByProduct: SalesByProduct[]
): FindSalesByProductSuccessAction => ({
  type: ActionType.findSalesByProductSuccess,
  salesByProduct,
});

export const findSalesByProductFailure = (
  error: HttpRequestError
): FindSalesByProductFailureAction => ({
  type: ActionType.findSalesByProductFailure,
  error,
});

export const findGCsByProductRequested = (
  sendToOndrive?: boolean
): FindGCsByProductRequestedAction => ({
  type: ActionType.findGCsByProductRequested,
  sendToOndrive: Boolean(sendToOndrive),
});

export const findGCsByProductSuccess = (
  gcsByProduct: GCsByProduct[]
): FindGCsByProductSuccessAction => ({
  type: ActionType.findGCsByProductSuccess,
  gcsByProduct,
});

export const findGCsByProductFailure = (
  error: HttpRequestError
): FindGCsByProductFailureAction => ({
  type: ActionType.findGCsByProductFailure,
  error,
});

export const findThirdPartySalesByItemsRequested = (
  sendToOndrive?: boolean
): FindThirdPartySalesByItemsRequestedAction => ({
  type: ActionType.findThirdPartySalesByItemsRequested,
  sendToOndrive: Boolean(sendToOndrive),
});

export const findThirdPartySalesByItemsSuccess = (
  thirdPartySalesByItems: ThirdPartySalesByItems[]
): FindThirdPartySalesByItemsSuccessAction => ({
  type: ActionType.findThirdPartySalesByItemsSuccess,
  thirdPartySalesByItems,
});

export const findThirdPartySalesByItemsFailure = (
  error: HttpRequestError
): FindThirdPartySalesByItemsFailureAction => ({
  type: ActionType.findThirdPartySalesByItemsFailure,
  error,
});

export const findPettyCashDifferenceRequested = (
  sendToOndrive?: boolean
): FindPettyCashDifferenceRequestedAction => ({
  type: ActionType.findPettyCashDifferenceRequested,
  sendToOndrive: Boolean(sendToOndrive),
});

export const findPettyCashDifferenceSuccess = (
  pettyCashDifference: PettyCashDifference[]
): FindPettyCashDifferenceSuccessAction => ({
  type: ActionType.findPettyCashDifferenceSuccess,
  pettyCashDifference,
});

export const findPettyCashDifferenceFailure = (
  error: HttpRequestError
): FindPettyCashDifferenceFailureAction => ({
  type: ActionType.findPettyCashDifferenceFailure,
  error,
});

export const findPettyCashDifferenceByPaymentTypeRequested = (
  sendToOndrive?: boolean
): FindPettyCashDifferenceByPaymentTypeRequestedAction => ({
  type: ActionType.findPettyCashDifferenceByPaymentTypeRequested,
  sendToOndrive: Boolean(sendToOndrive),
});

export const findPettyCashDifferenceByPaymentTypeSuccess = (
  pettyCashDifferenceByPaymentType: PettyCashDifferenceByPaymentType[]
): FindPettyCashDifferenceByPaymentTypeSuccessAction => ({
  type: ActionType.findPettyCashDifferenceByPaymentTypeSuccess,
  pettyCashDifferenceByPaymentType,
});

export const findPettyCashDifferenceByPaymentTypeFailure = (
  error: HttpRequestError
): FindPettyCashDifferenceByPaymentTypeFailureAction => ({
  type: ActionType.findPettyCashDifferenceByPaymentTypeFailure,
  error,
});

export const findSalesJournalRequested = (
  sendToOndrive?: boolean
): findSalesJournalRequestedAction => ({
  type: ActionType.findSalesJournalRequested,
  sendToOndrive: Boolean(sendToOndrive),
});

export const findSalesJournalGcsSuccess = (
  gcs: SalesJournalGcs[]
): FindSalesJournalGcsSuccessAction => ({
  type: ActionType.findSalesJournalGcsSuccess,
  gcs,
});

export const findSalesJournalGcsFailure = (
  error: HttpRequestError
): FindSalesJournalGcsFailureAction => ({
  type: ActionType.findSalesJournalGcsFailure,
  error,
});

export const findSalesJournalNcsSuccess = (
  ncs: SalesJournalNcs[]
): FindSalesJournalNcsSuccessAction => ({
  type: ActionType.findSalesJournalNcsSuccess,
  ncs,
});

export const findSalesJournalNcsFailure = (
  error: HttpRequestError
): FindSalesJournalNcsFailureAction => ({
  type: ActionType.findSalesJournalNcsFailure,
  error,
});

export const findSalesJournalFcsSuccess = (
  fcs: SalesJournalFcs[]
): FindSalesJournalFcsSuccessAction => ({
  type: ActionType.findSalesJournalFcsSuccess,
  fcs,
});

export const findSalesJournalFcsFailure = (
  error: HttpRequestError
): FindSalesJournalFcsFailureAction => ({
  type: ActionType.findSalesJournalFcsFailure,
  error,
});

export const findSalesJournalThirdPartySuccess = (
  thirdparty: SalesJournalThirdParty[]
): FindSalesJournalThirdPartySuccessAction => ({
  type: ActionType.findSalesJournalThirdPartySuccess,
  thirdparty,
});

export const findSalesJournalThirdPartyFailure = (
  error: HttpRequestError
): FindSalesJournalThirdPartyFailureAction => ({
  type: ActionType.findSalesJournalThirdPartyFailure,
  error,
});

export const changeMastersImportFilterCountry = (
  countries: Country[]
): ChangeMastersImportFilterCountryAction => ({
  type: ActionType.changeMasterImportFilterCountry,
  countries,
});

export const changeMastersImportFilterStartImportDate = (
  date: Date | null
): ChangeMastersImportFilterStartImportDateAction => ({
  type: ActionType.changeMasterImportFilterStartImportDate,
  date,
});

export const changeMastersImportFilterEndImportDate = (
  date: Date | null
): ChangeMastersImportFilterEndImportDateAction => ({
  type: ActionType.changeMasterImportFilterEndImportDate,
  date,
});

export const changeMastersImportFilterState = (
  state: string[]
): ChangeMastersImportFilterStateAction => ({
  type: ActionType.changeMasterImportFilterState,
  state,
});

export const changeClosingFilterCountry = (
  countries: Country[]
): ChangeClosingFilterCountryAction => ({
  type: ActionType.changeClosingFilterCountry,
  countries,
});

export const changeClosingFilterStore = (stores: Stores[]): ChangeClosingFilterStoreAction => ({
  type: ActionType.changeClosingFilterStore,
  stores,
});

export const changeClosingFilterStartBusinessDate = (
  date: Date | null
): ChangeClosingFilterStartBusinessDateAction => ({
  type: ActionType.changeClosingFilterStartBusinessDate,
  date,
});

export const changeClosingFilterEndBusinessDate = (
  date: Date | null
): ChangeClosingFilterEndBusinessDateAction => ({
  type: ActionType.changeClosingFilterEndBusinessDate,
  date,
});

export const changeClosingFilterViewMode = (
  viewMode: ClosingViewMode
): ChangeClosingFilterViewModeAction => ({
  type: ActionType.changeClosingFilterViewMode,
  viewMode,
});

export const changeClosingFilterFranchiseTypes = (
  franchiseTypes: ClosingFranchiseType[]
): ChangeClosingFilterFranchiseTypesAction => ({
  type: ActionType.changeClosingFilterFranchiseTypes,
  franchiseTypes,
});

export const changeClosingFilterFederativeEntities = (
  federativeEntities: string[]
): ChangeClosingFilterFederativeEntitiesAction => ({
  type: ActionType.changeClosingFilterFederativeEntities,
  federativeEntities,
});

export const changeClosingFilterCauseTypes = (
  causeTypes: MissingCauses[]
): ChangeClosingFilterCauseTypesAction => ({
  type: ActionType.changeClosingFilterCauseTypes,
  causeTypes,
});

export const changePendingInterfacesFilterCountry = (
  country: string
): ChangePendingInterfacesFilterCountryAction => ({
  type: ActionType.changePendingInterfacesFilterCountry,
  country,
});

export const changePendingInterfacesFilterStore = (
  stores: Stores[]
): ChangePendingInterfacesFilterStoreAction => ({
  type: ActionType.changePendingInterfacesFilterStore,
  stores,
});

export const changePendingInterfacesFilterEndBusinessDate = (
  date: Date | null
): ChangePendingInterfacesFilterEndBusinessDateAction => ({
  type: ActionType.changePendingInterfacesFilterEndBusinessDate,
  date,
});

export const changePendingInterfacesFilterStartBusinessDate = (
  date: Date | null
): ChangePendingInterfacesFilterStartBusinessDateAction => ({
  type: ActionType.changePendingInterfacesFilterStartBusinessDate,
  date,
});

export const changePendingInterfacesInterfacesFilterQuery = (
  filter: string
): ChangePendingInterfacesFilterQueryAction => ({
  type: ActionType.changePendingInterfacesFilterQuery,
  filter,
});

export const changePendingInterfacesFilterViewMode = (
  viewMode: PendinInterfacesViewMode
): ChangePendingInterfacesFilterViewModeAction => ({
  type: ActionType.changePendingInterfacesFilterViewMode,
  viewMode,
});

export const findPendingInterfacesSalesRequested = (
  startDate?: Date,
  endDate?: Date
): FindPendingInterfacesSalesRequestedAction => ({
  type: ActionType.findPendingInterfacesSalesRequested,
  startDate,
  endDate,
});

export const findPendingInterfacesCollectionsRequested = (
  startDate?: Date,
  endDate?: Date
): FindPendingInterfacesCollectionsRequestedAction => ({
  type: ActionType.findPendingInterfacesCollectionsRequested,
  startDate,
  endDate,
});

export const findPendingInterfacesSalesSuccess = (
  pendingInterfaces: PendingInterfaces[],
  startDate: Date,
  endDate: Date
): FindPendingInterfacesSalesSuccessAction => ({
  type: ActionType.findPendingInterfacesSalesSuccess,
  startDate,
  endDate,
  pendingInterfaces,
});

export const findPendingInterfacesSalesFailure = (
  error: HttpRequestError
): FindPendingInterfacesSalesFailureAction => ({
  type: ActionType.findPendingInterfacesSalesFailure,
  error,
});

export const findPendingInterfacesCollectionsSuccess = (
  pendingInterfaces: PendingInterfaces[],
  startDate: Date,
  endDate: Date
): FindPendingInterfacesCollectionsSuccessAction => ({
  type: ActionType.findPendingInterfacesCollectionsSuccess,
  startDate,
  endDate,
  pendingInterfaces,
});

export const findPendingInterfacesCollectionsFailure = (
  error: HttpRequestError
): FindPendingInterfacesCollectionsFailureAction => ({
  type: ActionType.findPendingInterfacesCollectionsFailure,
  error,
});

export const findMastersImportRequested = (): FindMastersImportRequestedAction => ({
  type: ActionType.findMastersImportRequested,
});

export const findMastersImportSuccess = (
  mastersImport: MastersImport[]
): FindMastersImportSuccessAction => ({
  type: ActionType.findMastersImportSuccess,
  mastersImport,
});

export const findMastersImportFailure = (
  error: HttpRequestError
): FindMastersImportFailureAction => ({
  type: ActionType.findMastersImportFailure,
  error,
});

export const mastersImportExecutionRequested = (): MastersImportExecutionRequestedAction => ({
  type: ActionType.mastersImportExecutionRequested,
});

export const mastersImportExecutionSuccess = (): MastersImportExecutionSuccessAction => ({
  type: ActionType.mastersImportExecutionSuccess,
});

export const mastersImportExecutionFailure = (
  error: HttpRequestError
): MastersImportExecutionFailureAction => ({
  type: ActionType.mastersImportExecutionFailure,
  error,
});

export const changeFilterAccountingEntriesFields =
  (): ChangeFilterAccountingEntriesFieldsAction => ({
    type: ActionType.changeFiltersAccountingEntriesFields,
  });

export const pendingInterfacesCreateSuccess = (): PendingInterfacesCreateSuccessAction => ({
  type: ActionType.pendingInterfacesCreateSuccess,
});

export const pendingInterfacesCreateFailure = (
  error: HttpRequestError
): PendingInterfacesCreateFailureAction => ({
  type: ActionType.pendingInterfacesCreateFailure,
  error,
});

export const changeExecutePendingInterfacesFilterCountry = (
  country: string
): ChangeExecutePendingInterfacesFilterCountryAction => ({
  type: ActionType.changeExecutePendingInterfacesFilterCountry,
  country,
});

export const changeExecutePendingInterfacesFilterStore = (
  stores: Stores[]
): ChangeExecutePendingInterfacesFilterStoreAction => ({
  type: ActionType.changeExecutePendingInterfacesFilterStore,
  stores,
});

export const changeExecutePendingInterfacesFilterBusinessDate = (
  date: Date | null
): ChangeExecutePendingInterfacesFilterBusinessDateAction => ({
  type: ActionType.changeExecutePendingInterfacesFilterBusinessDate,
  date,
});

export const changeExecutePendingInterfacesFilterStatus = (
  status: string
): ChangeExecutePendingInterfacesFilterStatusAction => ({
  type: ActionType.changeExecutePendingInterfacesFilterStatus,
  status,
});

export const executePendingInterfacesRequested = (): ExecutePendingInterfacesRequestedAction => ({
  type: ActionType.executePendingInterfacesRequested,
});

export const executePendingInterfacesSuccess = (
  executionStatusPendingInterfaces: ExecutionStatusPendingInterfaces[]
): ExecutePendingInterfacesSuccessAction => ({
  type: ActionType.executePendingInterfacesSuccess,
  executionStatusPendingInterfaces,
});

export const executePendingInterfacesFailure = (
  error: HttpRequestError
): ExecutePendingInterfacesFailureAction => ({
  type: ActionType.executePendingInterfacesFailure,
  error,
});

export const executePendingInterfacesParametersRequested =
  (): ExecutePendingInterfacesParametersRequestedAction => ({
    type: ActionType.executePendingInterfacesParametersRequested,
  });

export const executePendingInterfacesParametersSuccess = (
  parameters: PendingInterfacesParameters
): ExecutePendingInterfacesParametersSuccessAction => ({
  type: ActionType.executePendingInterfacesParametersSuccess,
  parameters,
});

export const executePendingInterfacesParametersFailure = (
  error: HttpRequestError
): ExecutePendingInterfacesParametersFailureAction => ({
  type: ActionType.executePendingInterfacesParametersFailure,
  error,
});
